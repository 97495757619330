import * as React from "react"
import Shell from "../layout/shell"

const Compatability: React.FC = () => {
  return (
    <Shell viewClass="compatability-view">
      Compatability info here
    </Shell>
  )
}

export default Compatability